<template>
  <section :class="['pt-2', menuIsOpen ? 'px-1' : 'px-0 px-md-5']" v-if="isLoading || allCourses.length">
    <h3 class="ms-2 mb-md-3 mb-2 bold bold-xs-none title title-bordered">
      {{ title }}
    </h3>

    <div class="row">
      <div class="col-md-10 px-4 mb-4 mt-1">
        <h6 class="mb-1 font-14" style="color: #646464">
          Filtrar por:
        </h6>

        <div class="row">
          <div class="col-auto mb-1 mb-md-0">
            <button @click="filterBy(localFilter === true ? null : true)" :class="[
              'bg-btn2 px-4 py-1',
              localFilter === true ? 'btn-fill' : 'btn-transparent',
            ]">
              Pro
            </button>
          </div>

          <div class="ps-0 col-auto">
            <button @click="filterBy(localFilter === false ? null : false)" :class="[
              'bg-btn2 px-4 py-1',
              localFilter === false ? 'btn-fill' : 'btn-transparent',
            ]">
              Free
            </button>
          </div>
        </div>
      </div>
    </div>

    <LoadingComp v-if="isLoading" />
    <Slider v-else-if="allCourses.length" :show-infos="true" :images="allCourses" :slides-to-show="this.slidesToShow"
      :rows="getRows" :father-class="'doc-slider'" />
  </section>
</template>

<script>
import Slider from '@/components/shared/Slider'
import CursoService from '@/service/cursoService'
import { mapGetters } from 'vuex'

export default {
  props: {
    filter: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },

  components: {
    Slider
  },

  async created() {
    if (this.isLoading || this.cursos.length) return
    this.CursoService = new CursoService(this.$resource)
    this.$store.dispatch('cursos/setIsLoading', true)
    const { body: cursos } = await this.CursoService.cursosAtivos()
    this.$store.dispatch('cursos/setIsLoading', false)
    this.$store.dispatch('cursos/setCursos', { cursos, url: this.$baseApiURL })
  },

  methods: {
    filterBy(filter) {
      this.localFilter = filter;
    },
  },

  data() {
    return {
      localFilter: null,
    };
  },

  computed: {
    getRows() {
      const divisor = this.isMobile ? 2 : 4
      if (!this.cursos.length) return 1
      return Math.ceil(this.allCourses.length / divisor)
    },

    slidesToShow() {
      if (this.width < 600) return 2
      if (this.width < 800) return 3
      return 4
    },

    ...mapGetters({
      menuIsOpen: 'configs/menuIsOpen',
      isMobile: 'configs/isMobile',
      width: 'configs/getWidth',
      isLoading: 'cursos/isLoading',
      cursos: 'cursos/getCursos'
    }),

    allCourses() {
      let list = this.cursos.filter(curso => this.localFilter === null ? curso : this.localFilter ? curso.isPro : !curso.isPro)

      if (!this.filter) return list.filter(({ disponivelEmBreve, workshop }) => disponivelEmBreve === 'N' && (!workshop || workshop === 'N'))
      if (this.filter === 'disponivelEmBreve') return list.filter(({ disponivelEmBreve, workshop }) => workshop === 'N' && disponivelEmBreve === 'S')
      return list.filter(curso => curso[this.filter] === 'S')
    }
  }
}

</script>

<style lang="sass" scoped>
.bg-btn2
  color: white
  text-transform: uppercase
  border-radius: 5px
  line-height: normal
  align-items: center
  display: inline-flex
  justify-content: center
  font-size: 12px !important
@media (max-width: 500px)
  h1
    font-size: 1.5em !important
</style>
